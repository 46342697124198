<template>
 <div>
    <h1>No dufam ze uz bude fungovat pipe</h1>
    <div class="container" v-for="link in links" :key="link.url">
        <a :href="link.url">{{ link.name }}</a>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      links: []
    };
  },
  mounted() {
    fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vSKS8t6oVMpRq4OsRboFlAgdMBbtyOsI-9t1LsmojdYbWZnIebAl-HzRCSkc0YC8Dq4t4VebCG4knxD/pub?output=csv')
      .then(response => response.text())
      .then(csv => {
        this.links = this.parseCSV(csv);
      });
  },
  /*
   mounted() {
    fetch('links.json')
      .then(response => response.json())
      .then(data => {
        this.links = data.links;
      });
  },*/
  methods: {
    parseCSV(csv) {
      const rows = csv.split('\n').slice(1);
      return rows.map(row => {
        const [name, url, img] = row.split(',');
        return { name, url, img: img || null };
      });
    }
  }
}
</script>

<style>

h1{
  text-align: center;
  margin-top: 8%;
  color: white;
  margin-bottom: 2%;
}

a{
  text-decoration: none;
  color:white;
  font-size: 30px;
}

.container{
  width: 30%;
  text-align: center;
  outline-style: outset;
  margin: auto;
  margin-bottom: 50px;
  padding: 30px;
  background-color:black;
  border-radius: 25px;
}


body{
  background-image: url('files/back.jpg');
}

</style>
